<template>
  <div class="m-2">
    <v-row justify="center">
      <v-col cols="12" lg="12" sm="12" md="12">
        <div class="mt-1">
          <v-card color="teal darken-1" elevation="5" dark>
            <v-card-text>
              <v-row class="m-3">
                <v-col
                  v-for="(v, i) in master.demografi"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  class="mt-1 mb-0 pt-2 pb-1"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.demografi[i] = e)"
                    :value="data.demografi[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :disabled="v.disabled"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" sm="6" md="6">
        <h4 class="primary--text">FORMULIR JASA DOKTER</h4>
        <v-simple-table>
          <thead>
            <tr>
              <th style="vertical-align: middle" class="text-left">Tanggal</th>
              <th style="vertical-align: middle" class="text-center">
                Keterangan
              </th>
              <!-- <th style="vertical-align: middle" class="text-center">Tarif</th> -->
              <th style="vertical-align: middle" class="text-center">Paraf</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) of listVisidokter">
              <tr v-if="item.jenis == 'Visite'" :key="index">
                <td class="text-left">{{ item.created_at }}</td>
                <td style="vertical-align: middle">
                  <input
                    type="text"
                    readonly
                    v-model="item.item_tindakan.nama_item_medis"
                    class="form-control form-control-sm"
                  />
                </td>
                <td style="vertical-align: middle" class="text-center">
                  <b><v-icon medium>mdi-check</v-icon></b>
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="1" class="text-center">Total</th>
              <th style="vertical-align: middle" class="text-center"></th>
              <th style="vertical-align: middle" class="text-center">
                <v-btn
                  color="blue darken-2"
                  @click="addvisitedokter"
                  small
                  dark
                  fab
                >
                  <v-icon small>mdi-plus</v-icon></v-btn
                >
              </th>
            </tr>
          </tfoot>
        </v-simple-table>
      </v-col>
      <!-- tindakan -->
      <v-col cols="12" lg="6" sm="6" md="6">
        <h4 class="primary--text">TINDAKAN</h4>
        <v-simple-table>
          <thead>
            <tr>
              <th style="vertical-align: middle" class="text-center">
                Tanggal
              </th>
              <th style="vertical-align: middle" class="text-center">
                Keterangan
              </th>
              <th style="vertical-align: middle" class="text-center">Paraf</th>
            </tr>
          </thead>
          <tbody v-for="(item3, index3) of listTindakan" :key="index3">
            <tr v-if="item3.jenis === 'tindakan'">
              <td>{{ item3.created_at }}</td>
              <td>
                <AutoComplateAjax
                  :sync-value="(e) => (item3.item_tindakan = e)"
                  :cvalue="item3.item_tindakan"
                  class="mt-4 ml-2 mr-2"
                ></AutoComplateAjax>
                <span class="primary--text">{{ item3.item_tindakan.jumlah }}</span>

              </td>
              <td style="vertical-align: middle" class="text-center">
                <b><v-icon medium>mdi-check</v-icon></b>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="1" class="text-center">Total</th>
              <th style="vertical-align: middle" class="text-center"></th>
              <th style="vertical-align: middle" class="text-center">
                <v-btn
                  color="blue darken-2"
                  @click="addTindakan"
                  small
                  dark
                  fab
                >
                  <v-icon small>mdi-plus</v-icon></v-btn
                >
              </th>
            </tr>
            <tr>
              <td colspan="4">
                <v-btn
                  class="btn-block"
                  @click="simpanDataTindakan"
                  dark
                  color="red darken-2"
                  medium
                  >Simpan Data Tindakan</v-btn
                >
              </td>
            </tr>
          </tfoot>
        </v-simple-table>
      </v-col>

      <!-- alkes -->
      <v-col cols="12" lg="6" sm="6" md="6">
        <h4 class="primary--text">ALKES</h4>
        <v-simple-table>
          <thead>
            <tr>
              <th style="vertical-align: middle" class="text-left">Tanggal</th>
              <th style="vertical-align: middle" class="text-center">
                Keterangan
              </th>
              <th style="vertical-align: middle" class="text-center">Paraf</th>
            </tr>
          </thead>
          <tbody v-for="(item2, index2) of listAlkes" :key="index2">
            <tr v-if="item2.jenis === 'alkes'">
              <td>{{ item2.created_at }}</td>
              <td>
                <AutoComplateAjax
                  :sync-value="(e) => (item2.item_tindakan = e)"
                  :cvalue="item2.item_tindakan"
                  class="mt-4 ml-2 mr-2"
                ></AutoComplateAjax>
                <!-- {{ item2.item_tindakan.jumlah }} -->
                <span class="primary--text">{{ item2.item_tindakan.jumlah }}</span>

              </td>
              <td style="vertical-align: middle" class="text-center">
                <b><v-icon medium>mdi-check</v-icon></b>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="1" class="text-center">Total</th>
              <th style="vertical-align: middle" class="text-center"></th>
              <th style="vertical-align: middle" class="text-center">
                <v-btn color="blue darken-2" @click="addAlkes" small dark fab>
                  <v-icon small>mdi-plus</v-icon></v-btn
                >
              </th>
            </tr>
            <tr>
              <td colspan="4">
                <v-btn
                  class="btn-block"
                  @click="simpanDataAlkes"
                  dark
                  color="red darken-2"
                  medium
                  >Simpan Data Alkes</v-btn
                >
              </td>
            </tr>
          </tfoot>
        </v-simple-table>
      </v-col>
      <v-col cols="12" lg="6" sm="6" md="6">
        <h4 class="primary--text">TINDAKAN NERS</h4>
        <v-simple-table>
          <thead>
            <tr>
              <th style="vertical-align: middle" class="text-center">
                Tanggal
              </th>
              <th style="vertical-align: middle" class="text-center">
                Keterangan
              </th>
              <th style="vertical-align: middle" class="text-center">Paraf</th>
            </tr>
          </thead>
          <tbody v-for="(item4, index4) of listTindakanNers" :key="index4">
            <tr v-if="item4.jenis === 'tindakanners'">
              <td>{{ item4.created_at }}</td>
              <td style="vertical-align: middle">
                <AutoComplateAjax
                  :sync-value="(e) => (item4.item_tindakan = e)"
                  :cvalue="item4.item_tindakan"
                  class="mt-4 ml-2 mr-2"
                ></AutoComplateAjax>
                <span class="primary--text">{{ item4.item_tindakan.jumlah }}</span>

              </td>
              <td style="vertical-align: middle" class="text-center">
                <b><v-icon medium>mdi-check</v-icon></b>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="1" class="text-center">Total</th>
              <th style="vertical-align: middle" class="text-center"></th>
              <th style="vertical-align: middle" class="text-center">
                <v-btn color="blue darken-2" @click="addTindakanNers" small dark fab>
                  <v-icon small>mdi-plus</v-icon></v-btn
                >
              </th>
            </tr>
            <tr>
              <td colspan="4">
                <v-btn
                  class="btn-block"
                  @click="simpanDataTindakanNers"
                  dark
                  color="red darken-2"
                  medium
                  >Simpan Data Alkes</v-btn
                >
              </td>
            </tr>
          </tfoot>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import Master from '../../data/cppt'
import SmartWidget from '@/components/SmartWidget.vue'
import AutoComplateAjax from './AutoCompleteAjax.vue'

// import { apiKedua } from '../../plugins/supports'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading,
  // eslint-disable-next-line no-unused-vars
  jsonPrettier
} from '../../plugins/supports'

export default {
  components: {
    SmartWidget,
    // eslint-disable-next-line vue/no-unused-components
    AutoComplateAjax
  },
  data () {
    return {
      master: Master,
      listVisidokter: [],
      listAlkes: [],
      listTindakanNers: [],
      listTindakan: [],
      pemakai: '',

      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: '',
          notes: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      }
    }
  },
  mounted () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getVisite()
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.pemakai = JSON.parse(localStorage.getItem('user'))

      this.getVisite()
    }
  },
  methods: {
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    addvisitedokter () {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      var yyyy = today.getFullYear()

      var hh = String(today.getHours()).padStart(2, '0')
      var ii = String(today.getMinutes()).padStart(2, '0')
      var ss = String(today.getSeconds()).padStart(2, '0')

      // today = mm + '/' + dd + '/' + yyyy;
      today = dd + '-' + mm + '-' + yyyy + ' ' + hh + ':' + ii + ':' + ss
      this.listVisidokter.push({
        id_tindakan_medis: '',
        reg_id: '',
        folio_id: '',
        sub_unit_id: '',
        item_tindakan: '',
        created_by: '',
        created_at: today,
        deleted_by: '',
        jenis: '',
        visite_id_dokter: '',
        paraf: ''
      })

      console.log('11')
    },
    addTindakan () {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      var yyyy = today.getFullYear()

      var hh = String(today.getHours()).padStart(2, '0')
      var ii = String(today.getMinutes()).padStart(2, '0')
      var ss = String(today.getSeconds()).padStart(2, '0')

      // today = mm + '/' + dd + '/' + yyyy;
      today = dd + '-' + mm + '-' + yyyy + ' ' + hh + ':' + ii + ':' + ss
      this.listTindakan.push({
        id_tindakan_medis: '',
        reg_id: '',
        folio_id: '',
        sub_unit_id: '',
        item_tindakan: '',
        created_by: '',
        created_at: today,
        deleted_by: '',
        jenis: 'tindakan',
        visite_id_dokter: '',
        paraf: ''
      })

      console.log('11')
    },
    addTindakanNers () {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      var yyyy = today.getFullYear()

      var hh = String(today.getHours()).padStart(2, '0')
      var ii = String(today.getMinutes()).padStart(2, '0')
      var ss = String(today.getSeconds()).padStart(2, '0')

      // today = mm + '/' + dd + '/' + yyyy;
      today = dd + '-' + mm + '-' + yyyy + ' ' + hh + ':' + ii + ':' + ss
      this.listTindakanNers.push({
        id_tindakan_medis: '',
        reg_id: '',
        folio_id: '',
        sub_unit_id: '',
        item_tindakan: '',
        created_by: '',
        created_at: today,
        deleted_by: '',
        jenis: 'tindakanners',
        visite_id_dokter: '',
        paraf: ''
      })

      console.log('11')
    },
    addAlkes () {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      var yyyy = today.getFullYear()

      var hh = String(today.getHours()).padStart(2, '0')
      var ii = String(today.getMinutes()).padStart(2, '0')
      var ss = String(today.getSeconds()).padStart(2, '0')

      // today = mm + '/' + dd + '/' + yyyy;
      today = dd + '-' + mm + '-' + yyyy + ' ' + hh + ':' + ii + ':' + ss
      this.listAlkes.push({
        id_tindakan_medis: '',
        reg_id: '',
        folio_id: '',
        sub_unit_id: '',
        item_tindakan: '',
        created_by: '',
        created_at: today,
        deleted_by: '',
        jenis: 'alkes',
        visite_id_dokter: '',
        paraf: ''
      })
    },

    simpanDataAlkes () {
      const tindakan = []
      this.listAlkes.forEach((item) => {
        // eslint-disable-next-line eqeqeq
        if (item.jenis == 'alkes') {
          tindakan.push({
            id_tindakan_medis: item.id_tindakan_medis,
            reg_id: item.reg_id,
            folio_id: item.folio_id,
            sub_unit_id: item.sub_unit_id,
            item_tindakan: item.item_tindakan,
            harga: item.harga,
            created_by: item.created_by,
            created_at: item.created_at,
            deleted_by: item.deleted_by,
            jenis: item.jenis,
            visite_id_dokter: item.visite_id_dokter
          })
        }
      })
      // eslint-disable-next-line no-unused-vars
      const query = {
        reg_id: this.$route.query.registration_id,
        folio_id: this.$route.query.folio_id,
        data: tindakan,
        jenis: 'alkes',
        sub_unit_id: this.$route.query.sub_unit_id,
        createdby: this.pemakai.employee_id,
        nama_input: this.pemakai.employee_name
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-tindakan',
        query,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    simpanDataTindakan () {
      // console.log(this.listTindakan)
      // eslint-disable-next-line no-unused-vars

      const tindakan = []
      this.listTindakan.forEach((item) => {
        // eslint-disable-next-line eqeqeq
        if (item.jenis == 'tindakan') {
          tindakan.push({
            id_tindakan_medis: item.id_tindakan_medis,
            reg_id: item.reg_id,
            folio_id: item.folio_id,
            sub_unit_id: item.sub_unit_id,
            item_tindakan: item.item_tindakan,
            harga: item.harga,
            created_by: item.created_by,
            created_at: item.created_at,
            deleted_by: item.deleted_by,
            jenis: item.jenis,
            visite_id_dokter: item.visite_id_dokter
          })
        }
      })
      const query = {
        reg_id: this.$route.query.registration_id,
        folio_id: this.$route.query.folio_id,
        data: tindakan,
        jenis: 'tindakan',
        sub_unit_id: this.$route.query.sub_unit_id,
        createdby: this.pemakai.employee_id,
        nama_input: this.pemakai.employee_name
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-tindakan',
        query,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    simpanDataTindakanNers () {
      // console.log(this.listTindakan)
      // eslint-disable-next-line no-unused-vars

      const tindakan = []
      this.listTindakanNers.forEach((item) => {
        // eslint-disable-next-line eqeqeq
        if (item.jenis == 'tindakanners') {
          tindakan.push({
            id_tindakan_medis: item.id_tindakan_medis,
            reg_id: item.reg_id,
            folio_id: item.folio_id,
            sub_unit_id: item.sub_unit_id,
            item_tindakan: item.item_tindakan,
            harga: item.harga,
            created_by: item.created_by,
            created_at: item.created_at,
            deleted_by: item.deleted_by,
            jenis: item.jenis,
            visite_id_dokter: item.visite_id_dokter
          })
        }
      })
      const query = {
        reg_id: this.$route.query.registration_id,
        folio_id: this.$route.query.folio_id,
        data: tindakan,
        jenis: 'tindakanners',
        sub_unit_id: this.$route.query.sub_unit_id,
        createdby: this.pemakai.employee_id,
        nama_input: this.pemakai.employee_name
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-tindakan',
        query,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    getVisite () {
      // eslint-disable-next-line no-unused-vars
      const query = {
        reg_id: this.$route.query.registration_id,
        folio_id: this.$route.query.folio_id
      }
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-tindakan-medis',
        query,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            const listVisidokter = []

            results.forEach((item) => {
              // if (item.jenis === 'Visite') {
              listVisidokter.push({
                id_tindakan_medis: item.id_tindakan_medis,
                reg_id: item.reg_id,
                folio_id: item.folio_id,
                sub_unit_id: item.sub_unit_id,
                item_tindakan: JSON.parse(item.item_tindakan),
                harga: item.harga,
                created_by: item.created_by,
                created_at: item.created_at,
                deleted_by: item.deleted_by,
                jenis: item.jenis,
                visite_id_dokter: item.visite_id_dokter
              })
              // }
            })
            this.listTindakan = listVisidokter
            this.listVisidokter = listVisidokter
            this.listAlkes = listVisidokter
            this.listTindakanNers = listVisidokter
          }
        },
        'JSON'
      )
    }
  }
}
</script>
