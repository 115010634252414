export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,
      col: 4
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      data: null,
      disabled: true,

      col: 4
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      disabled: true,

      col: 4
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      disabled: true,

      col: 4
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      disabled: true,

      col: 4
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 12
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      label: 'Dokter DPJP Utama',
      disabled: true,

      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      data: null,
      label: 'Dokter DPJP Kedua',
      disabled: true,

      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      disabled: true,
      label: 'No Reg',
      col: 6
    }
  },
  form1: {
    therapi: {
      label: 'Therapi Yang Sudah Diberikan',
      widget: 'wtextarea',
      data: null,
      col: 12
    },
    alasan_kontrol: {
      label: 'Alasan Kontrol',
      widget: 'wtextarea',
      data: null,
      col: 12
    },
    diagnosa: {
      label: 'Kode ICD Utama',
      widget: 'wautocomplate',
      data: [],
      col: 7
    },
    diagnosa_lainnya: {
      label: 'Diagnosa Lainnya',
      widget: 'wtextarea',
      data: null,
      col: 5
    },
    tgl_kontrol: {
      widget: 'wdatenormal',
      data: null,
      col: 4,
      label: 'Tanggal Kontrol'
    },
    poli_tujuan: {
      label: 'Poliklinik Yang Dituju',
      widget: 'wautocomplatesingle',
      col: 4,
      data: ''
    },
    sep: {
      widget: 'wselect',
      data: [

      ],
      col: 4
    }
  }
}
