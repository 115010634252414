<template>
  <v-container fluid class="mb-2">
    <h3 class="mt-4 mb-2">Penunjang</h3>
    <div class="mt-1" v-if="data.demografi.notes">
      <v-alert dense shaped prominent type="error">
        Catatan Adm : {{ data.demografi.notes }}
      </v-alert>
    </div>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10" md="10" sm="10">
        <v-card color="teal darken-1 mt-2 mb-2" dark>
          <v-card-text>
            <v-row class="mt-2 p-3 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :disabled="v.disabled"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs v-model="tab" centered style="font-size: 12px" class="mt-2">
      <v-tab>Laboratorium</v-tab>
      <v-tab>Radiologi</v-tab>
      <v-tab>BDRS</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <Laboratorium />
      </v-tab-item>
      <v-tab-item>
        <Radiologi />
      </v-tab-item>
      <v-tab-item>
        <BDRS />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import Master from '../../data/form-demografi-ri'
// eslint-disable-next-line no-unused-vars
import SmartWidget from '@/components/SmartWidget.vue'
import Laboratorium from './Laboratorium'
import Radiologi from './Radiologi'
import BDRS from './BDRS'

import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SmartWidget,
    Laboratorium,
    Radiologi,
    // eslint-disable-next-line vue/no-unused-components
    BDRS
  },
  data () {
    return {
      snackbar: false,
      snack_text: 'Hello, Im a snackbar',
      snack_color: 'green',
      url_emr: 'http://emr.rs-syafira.com/',
      master: Master,
      tab: '',
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          folio_id: '',
          reg_id: '',
          notes: ''
        },
        inputan_terapi: {
          diagnosa: '',
          permintaan_terapi: ''
        }
      },
      user: JSON.parse(localStorage.getItem('user')),
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      }
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      // console.log(this.master
      this.getDataPasien(this.$route.query.folio_id)
    }
  },
  methods: {
    getDataPasien (v) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: v },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    }
  }
}
</script>
