<template>
  <div class="mt-2">
    <v-row class="mt-1">
      <v-col cols="12" lg="6" md="6" sm="6">
        <v-card color="teal darken-1" dark>
          <v-card-title>Demografi Pasien</v-card-title>

          <v-card-text>
            <v-row class="mt-1 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :disabled="v.disabled ? v.disabled : false"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-title>Form Kontrol</v-card-title>
          <v-card-text>
            <v-row class="mt-2 mb-0">
              <v-col
                v-for="(v, i) in master.form1"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form1[i] = e)"
                  :value="data.form1[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-btn
                  @click="postSave"
                  color="primary"
                  medium
                  v-if="!!regid"
                  class="btn-block"
                >
                  Simpan Form
                </v-btn>
              </v-col>
            </v-row>
            <!-- {{ createdBy }} -->
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6">
        <div id="table-scroll" class="table-scroll">
          <div class="table-wrap mt-1">
            <v-simple-table d fixed-header class="m-2">
              <thead>
                <tr>
                  <th
                    class="text-center"
                    width="5%"
                    style="vertical-align: middle"
                  >

                  </th>
                  <th class="text-center" style="vertical-align: middle">
                    Nama Dokter
                  </th>
                  <th class="text-center" style="vertical-align: middle">
                    Tanggal Kontrol
                  </th>
                  <th class="text-center" style="vertical-align: middle">
                    Poliklinik Kontrol
                  </th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="(itemdokter, index) of listData" :key="index">
                  <td style="vertical-align: middle">
                    <v-btn x-small color="orange darken-2" dark @click="cetakSuratKontrol(itemdokter)" fab
                      ><v-icon small>mdi-printer</v-icon></v-btn
                    >
                  </td>
                  <td style="vertical-align: middle">{{ itemdokter?.dokter }}</td>
                  <td style="vertical-align: middle">
                    {{
                      itemdokter.form?.form1?.tgl_kontrol
                        | moment("DD MMMM YYYY")
                    }}
                  </td>
                  <td class="text-center" style="vertical-align: middle">
                    {{ itemdokter.form?.form1?.poli_tujuan?.text }}
                  </td>

                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
// eslint-disable-next-line no-unused-vars
import Master from '../../data/lembar-kontrol'
import Dialog from '@/components/Dialog'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  isEmpty
} from '../../plugins/supports'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SmartWidget,
    // eslint-disable-next-line vue/no-unused-components
    DialogConfirm: Dialog
  },
  props: {
    createdBy: {
      require: false,
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      url_emr: 'http://emr.rs-syafira.com/',
      menukanan: [
        {
          text: 'Form Lembar Konsultasi',
          level: 3,
          to: '#test0'
        },
        {
          text: 'History Konsultasi',
          level: 4,
          to: '#test1'
        }
      ],
      searchKonsultasi: '',
      permintaans_page: 1,
      regid: this.$route.query.registration_id,
      kirimpesan: 'Assalammualaikum',
      permintaans_per_page: 9,
      permintaan_pagination_container: '',
      permintaans_total: '',

      listData: [],
      isActive: false,
      items: ['Rawat Bersama', 'Ahli Rawat', 'Konsul'],
      master: Master,
      user: JSON.parse(localStorage.getItem('user')),
      namaDokter: '',
      listKonsultasi: [],
      pegawai: '',
      sub: this.$route.query.sub_unit_id,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          reg_id: '',
          folio_id: ''
        },
        form1: {
          therapi: '',
          alasan_kontrol: '',
          diagnosa: '',
          diagnosa_lainnya: '',
          tgl_kontrol: '',
          jam_kontrol: '',
          poli_tujuan: ''
        }
      },
      rules: {
        required: (value) => {
          if (this.data.cara_bayar === 'BPJS') {
            return !isEmpty(value) || 'Tidak Boleh Kosong'
          } else {
            return true
          }
        }
      }
    }
  },
  created () {
    // this.set_contents(this.menukanan)
    if (this.$route.query.no_rm != null) {
      this.pegawai = JSON.parse(localStorage.getItem('user'))

      this.getDataPasien(this.$route.query.no_rm)
      // this.getDataLembarKonsultasi(this.folioYangDipakai)
      // this.getDokter()
    }
  },
  methods: {
    getDataPasien (v) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: this.$route.query.folio_id },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    umur (tglLahir) {
      var dob = new Date(tglLahir)
      var dobYear = dob.getYear()
      var dobMonth = dob.getMonth()
      var dobDate = dob.getDate()
      var yearAge = ''
      // get the current date from the system
      var now = new Date()
      // extract the year, month, and date from current date
      var currentYear = now.getYear()
      var currentMonth = now.getMonth()
      var currentDate = now.getDate()

      // declare a variable to collect the age in year, month, and days
      var age = {}
      var ageString = ''

      // get years
      yearAge = currentYear - dobYear

      // get months
      if (currentMonth >= dobMonth) {
        // get months when current month is greater
        var monthAge = currentMonth - dobMonth
      } else {
        yearAge--
        // eslint-disable-next-line no-redeclare
        var monthAge = 12 + currentMonth - dobMonth
      }

      // get days
      if (currentDate >= dobDate) {
        // get days when the current date is greater
        var dateAge = currentDate - dobDate
      } else {
        monthAge--
        // eslint-disable-next-line no-redeclare
        var dateAge = 31 + currentDate - dobDate

        if (monthAge < 0) {
          monthAge = 11
          yearAge--
        }
      }
      // group the age in a single variable
      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
      }

      if (age.years > 0 && age.months > 0 && age.days > 0) {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      } else if (age.years === 0 && age.months === 0 && age.days > 0) {
        ageString = ' ' + age.days + ' Hari'
        // eslint-disable-next-line brace-style
      }
      // when current month and date is same as birth date and month
      else if (age.years > 0 && age.months === 0 && age.days === 0) {
        ageString = age.years + ' Tahun. Happy Birthday!!'
      } else if (age.years > 0 && age.months > 0 && age.days === 0) {
        ageString = age.years + ' Tahun dan ' + age.months + ' Bulan.'
      } else if (age.years === 0 && age.months > 0 && age.days > 0) {
        ageString = age.months + ' Bulan dan ' + age.days + ' Hari.'
      } else if (age.years > 0 && age.months === 0 && age.days > 0) {
        ageString = age.years + ' Tahun, dan' + age.days + ' Hari.'
      } else if (age.years === 0 && age.months > 0 && age.days === 0) {
        ageString = age.months + ' Bulan.'
        // eslint-disable-next-line brace-style
      }
      // when current date is same as dob(date of birth)
      else {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      }

      // console.log(ageString)
      return ageString
    },
    postSave () {
      // eslint-disable-next-line no-unused-vars
      const data = {
        norm: this.$route.query.no_rm,
        registrationid: this.$route.query.registration_id,
        folioid: this.$route.query.folio_id,
        ruanganasal: this.$route.query.sub_unit_id,
        type: this.$route.query.type,
        employee_id: this.createdBy.created_by,
        form: this.data,
        sep: this.data.form1.sep
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-lembar-kontrol',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            successMsg(msg)
            setTimeout(() => {
              this.getListKontrol()
            }, 1000)
          } else {
            errorMsg(msg)
          }
        },
        'json'
      )
    },
    getSuratKontrol () {
      // eslint-disable-next-line no-unused-vars
      const query = {
        folio_id: this.$route.query.folio_id,
        created_by: this.createdBy.created_by
      }
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-data-surat-kontrol',
        query,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            // successMsg(msg)
            this.data = JSON.parse(results.form)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    cetakSuratKontrol (VALUE) {
      window
        .open(
          baseUrlEmr + `laporan-medis/cetak-surat-kontrol-rawat-inap?params=${window.btoa(VALUE.id_lembar_surat)}`,
          '_blank'
        )
        .focus()
    },
    getListKontrol () {
      // eslint-disable-next-line no-unused-vars
      const query = {
        folio_id: this.$route.query.folio_id
      }
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/list-surat-kontrol',
        query,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            // successMsg(msg)

            var listData = []

            results.forEach((item) => {
              listData.push({
                id_lembar_surat: item.id_lembar_surat,
                dokter: item.dokter,
                form: JSON.parse(item.form)
              })
            })
            this.listData = listData

            console.log(this.listData)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style scoped>
.v-list--three-line .v-list-item,
.v-list-item--three-line {
  min-height: 28px !important;
}

.table-scroll {
  position: relative;
  max-width: 1800px;
  margin: auto;
  overflow: hidden;
  /* border: 1px solid #000; */
}

.table-wrap {
  width: 100%;
  overflow: auto;
}

.table-scroll table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  /* border: 0.5px solid #000; */
  /* background: #fff; */
  white-space: nowrap;
  vertical-align: top;
}

.table-scroll thead,
.table-scroll tfoot {
  background: #f9f9f9;
}

.clone {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.clone th,
.clone td {
  visibility: hidden;
}

.clone td,
.clone th {
  border-color: transparent;
}

.clone tbody th {
  visibility: visible;
  color: red;
}

.clone .fixed-side {
  border: 1px solid #000;
  background: #eee;
  visibility: visible;
}

.clone thead,
.clone tfoot {
  background: transparent;
}
hr {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
