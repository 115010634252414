<!-- eslint-disable vue/no-parsing-error -->
<template>
  <v-container fluid class="mb-2">
    <v-row justify="center">
      <v-col cols="12" lg="4" sm="4" md="4">
        <v-btn class="ma-2" @click="paket('12677')" color="primary darken-1" small>Paket Pre Op 1</v-btn>
        <v-btn class="ma-2" @click="paket('12679')" color="primary darken-2" small>Paket Pre Op 2</v-btn>
        <p>Button Ini Khusus Digunakan Jika Pemeriksaan Operasi Dilakukan Per Item, Jika Pemeriksaan Diminta Paket Pilih Button <span class="text-danger"><b>Merah Paket Pre Op!!</b></span></p>

        <v-card-text>
          <fieldset class="scheduler-border">
            <legend class="scheduler-border primary--text">
              Order Laboratorium <v-icon>mdi-list-box</v-icon>
            </legend>
            <table class="table table-bordered table-md" width="100%">
              <thead class="table-success">
                <tr>
                  <th class="text-left" style="vertical-align: middle">
                    Nama Tindakan
                  </th>
                  <th class="text-center" style="vertical-align: middle">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-danger"
                  v-for="(itemOrder, index) in listOrder"
                  :key="itemOrder.id_order"
                >
                  <td style="vertical-align: middle">
                    {{ itemOrder.item_name }}
                  </td>
                  <td style="vertical-align: middle" class="text-center">
                    <v-btn
                      fab
                      class="error"
                      :disabled="cekOrder"
                      @click="deleteTindakan(index)"
                      x-small
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td style="vertical-align: middle" colspan="2">
                    <v-autocomplete
                      return-object
                      outlined
                      item-text="text"
                      item-value="value"
                      v-model="tindakan"
                      :items="getTindakan"
                      :search-input.sync="searchTindakan"
                      @change="addTindakan()"
                      label="Nama Item"
                      placeholder="Cari Nama Item Laboratorium"
                    ></v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle" colspan="2">
                    <v-textarea
                      class="mt-0"
                      auto-grow
                      outlined
                      label="Diagnosa/Keterangan Klinis Tambahan"
                      rows="4"
                      row-height="20"
                      v-model="keterangan"
                    ></v-textarea>
                    <v-text-field
                      class="mt-0"
                      auto-grow
                      outlined
                      type="time"
                      label="Jam Sample Diambil"
                      rows="4"
                      row-height="20"
                      v-model="jamsampling"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <v-btn
                      depressed
                      class="btn-block"
                      color="yellow"
                      @click="simpan"
                      medium
                    >
                      Order Laboratorium
                    </v-btn>
                  </td>
                </tr>
              </tfoot>
            </table>
          </fieldset>
        </v-card-text>
      </v-col>
      <v-col cols="12" lg="8" sm="8" md="8">
        <fieldset class="scheduler-border">
          <legend class="scheduler-border mb-3 primary--text">
            Blanko <v-icon>mdi-list-box</v-icon>
          </legend>
          <v-text-field
            label="Cari Group Item"
            v-model="searchQuery"
            @keyup="cariitem"
            outlined
            dense
          ></v-text-field>
          <v-row>
            <v-col
              v-for="(itemlabor, indexlabor) of blanko"
              :key="indexlabor"
              cols="12"
              lg="4"
              sm="4"
              md="4"
            >
              <v-btn
                :color="checkwarna(itemlabor.item_group_id)"
                class="btn-block"
                large
                style="font-size: 11px"
                dark
                @click="
                  setItem(
                    itemlabor.list,
                    itemlabor.item_group_id,
                    itemlabor.group_name
                  )
                "
              >
                <b>{{ itemlabor.group_name }}</b>
              </v-btn>
            </v-col>
          </v-row>
        </fieldset>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="12" sm="12" md="12">
        <fieldset class="scheduler-border">
          <legend class="scheduler-border mb-1 primary--text">
            History Order Laboratorium <v-icon>mdi-list-box</v-icon>
          </legend>
          <v-simple-table danse fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center" style="vertical-align: middle">
                    Aksi
                  </th>
                  <!-- <th class="text-center">NO.ORDER</th> -->
                  <th class="text-left">TANGGAL</th>
                  <th class="text-left">STATUS</th>
                  <th class="text-center">ITEM</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(his, index) of listHistoryOrder" :key="index">
                  <td
                    class="text-center"
                    width="150px"
                    style="vertical-align: middle"
                  >
                    <v-btn @click="hapusOrder(his)" x-small class="error" fab
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >&nbsp;<v-btn
                      @click="accDialog(his)"
                      x-small
                      class="info"
                      fab
                      v-if="his.status == 'DIORDER'"
                      ><v-icon>mdi-checkbox-marked</v-icon></v-btn
                    >
                  </td>
                  <!-- <td class="text-center">
                          {{ his.no_order_penunjang }}
                        </td> -->
                  <td width="240px">{{ his.tgl_order }}</td>
                  <td><v-chip color="red darken-1" dark>{{ status(his.status) }}</v-chip></td>
                  <td>
                    <span
                      v-for="itemOrderResult of JSON.parse(his.item_order)"
                      :key="itemOrderResult.item_id"
                    >
                      <span
                        ><b>{{ itemOrderResult.item_name }}</b
                        >,
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </fieldset>
      </v-col>
    </v-row>

    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <dialog-acc
      :show-dialog="dialogShowAcc"
      :progress="dialogProgressAcc"
      :title="dialogTitleAcc"
      :idOrder="idorderpenunjang"
      ref="acc"
      :message="dialogMessageAcc"
      :negative-button="dialogActionNBtnAcc"
      :positive-button="dialogActionPBtnAcc"
      :disabled-negative-btn="dialogDisableNBtnAcc"
      :disabled-positive-btn="dialogDisablePBtnAcc"
    />
    <v-dialog v-model="tol" max-width="500">
      <v-card>
        <v-card-title class="headline primary--text">
          {{ namaGroup }}</v-card-title
        >
        <v-card-text>
          <v-text-field
            label="Cari Nama Item Labor"
            v-model="searchQueryItem"
            outlined
            dense
          ></v-text-field>
          <ul class="list-group">
            <li
              v-for="(child, indexchild) of searchListItemDetail"
              :key="indexchild"
              class="list-group-item"
            >
              <v-btn
                style="font-size: 12px"
                class="btn-block"
                color="primary"
                medium
                dark
                @click="settindakan(child.item_id, child.item_name)"
              >
                <span v-if="child.item_group_id == group_id">
                  &nbsp {{ child.item_name }}
                </span>
              </v-btn>
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
import Dialog from '@/components/Dialog'
import DialogAcc from '@/components/DialogAcc'
export default {
  components: {
    DialogConfirm: Dialog,
    DialogAcc
  },
  data () {
    return {
      column: null,
      show: false,
      namaGroup: '',
      blanko: [],
      jamsampling: '',
      tol: false,
      listItem: [],
      user: JSON.parse(localStorage.getItem('user')),
      snackbar: false,
      pesanError: 'Hello, Im a snackbar',
      waktuTimeOut: 200,
      listOrder: [],
      searchQueryItem: '',
      pesanOrder: 'Last Oderan Laboratorium',
      folioYangDipakai: '',
      items: '',
      searchQuery: '',
      keterangan: '',
      cekOrder: false,
      tab: '',
      itemsPilih: ['CITO', 'PERMINTAAN'],
      jenisPermintaan: '',
      id_order: 0,
      keteranganAcc: '',
      reg: '',
      sub_unit_id: '',
      rm: '',
      listChild: [],
      folio: '',
      url_emr: 'http://emr.rs-syafira.com/',
      itemTindakan: [],
      tindakan: [],
      checkbox: '',
      listHistoryOrder: [],
      group_id: '',

      searchTindakan: '',

      dialogShow: false,
      dialogTitle: 'Batal',
      dialogMessage: 'Apakah Anda Ingin Membatalkan Order Ini?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogActionPBtn: () => {
        // this.dialogShow = false
      },

      // diaglogAcc
      dialogShowAcc: false,
      dialogTitleAcc: 'Konfirmasi Untuk Pemeriksaan Item Labor Ini',
      dialogMessageAcc: 'Apakah Item Ini Sudah Di Acc Untuk Di Periksa',
      dialogProgressAcc: false,
      idorderpenunjang: '',
      dialogDisableNBtnAcc: false,
      dialogDisablePBtnAcc: false,
      dialogActionNBtnAcc: () => {
        this.dialogShowAcc = false
        this.historyLabor()
      },
      dialogActionPBtnAcc: () => {
        // this.dialogShow = false
        // this.getData()
      }
    }
  },
  created () {
    // this.detail_program = []
    if (this.$route.query.no_rm != null) {
      this.getTypeItem()
      this.getData()
      this.historyLabor()

      this.show = true
    }
  },
  computed: {
    getTindakan () {
      return this.itemTindakan.map((v) => ({
        text: v.item_name,
        value: v.item_id
      }))
    },
    searchListItemDetail () {
      // console.log('123213123')
      //   console.log(this.searchQuery);
      if (this.searchQueryItem) {
        // console.log(this.blanko)
        return this.listChild.filter((item) => {
          return item.item_name
            .toLowerCase()
            .includes(this.searchQueryItem.toLowerCase())
          // return item.item_name.startsWith(this.searchQueryItem.toLowerCase())
        })
      } else {
        return this.listChild
      }
    }
  },
  watch: {
    searchTindakan (val) {
      // console.log(val)
      if (val && (!this.tindakan || this.tindakan.text !== val)) {
        apiKedua
          .get('labor/get-tindakan?q=' + val, {})
          .then((response) => (this.itemTindakan = response.data.results))
          .catch(function (error) {
            // handle error
            console.log(error)
          })
          .then(function () {
            // always executed
          })
      }
    }
  },
  methods: {
    status (RESULTS) {
      if (RESULTS === 'DI ACC - PENDING') {
        return 'STATUS RESERVASI-SEMPEL BELUM DIAMBIL-ORDERAN SUDAH SAMPAI LABOR'
      } else if (RESULTS === 'DI ACC') {
        return 'STATUS BERHASIL ORDER-SUDAH SAMPAI LABOR'
      } else if (RESULTS === 'DIORDER') {
        return 'STATUS ORDERAN BELUM SAMPAI LABOR'
      } else {
        return RESULTS
      }
    },
    paket (id) {
      // eslint-disable-next-line no-undef
      $.get(baseUrl + 'igd/set-nama-labor', { id: id }, (res) => {
        // eslint-disable-next-line no-unused-vars
        const { con, msg, results } = res
        if (con) {
          this.listOrder = results
        } else {

        }
      }, 'JSON')
    },
    setItem (v, g, n) {
      this.tol = true
      this.listChild = v
      this.group_id = g
      this.namaGroup = n
    },
    getData () {
      var blanko = []
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'labor/blanko-labor',
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            for (const item of results) {
              blanko.push({
                item_type_id: item.item_type_id,
                item_group_id: item.item_group_id,
                // list: this.getDataSingle(item.item_group_id),
                group_name: item.group_name,
                list: item.list
              })
            }
          } else {
          }
        },
        'JSON'
      )
      this.blanko = blanko
    },
    // getDataSingle (id) {
    //   // eslint-disable-next-line no-undef
    //   $.get(
    //     baseUrl + 'labor/list-item',
    //     { group: id },
    //     (res) => {
    //       // eslint-disable-next-line no-unused-vars
    //       const { con, msg, results } = res
    //       if (con) {
    //         this.listItem = results
    //       } else {
    //       }
    //     },
    //     'JSON'
    //   )
    // }
    checkwarna (id) {
      var seringdipakai = ['129', '182', '136', '131', '211', '199']
      if (id === '210') {
        return 'red'
      } else if (seringdipakai.includes(id)) {
        return 'orange accent-3'
      } else {
        return 'teal darken-1'
      }
    },
    cariitem () {
      if (!this.searchQuery) {
        this.getData()
        return
      }
      // eslint-disable-next-line camelcase
      var array_item_group_id_hasil = []
      for (const item of this.blanko) {
        // console.log(item)
        var data = item.group_name
        var result = data
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
        if (result === true) {
          array_item_group_id_hasil.push(item.item_group_id)
        }

        if (result === false) {
          for (const force of item.list) {
            // eslint-disable-next-line camelcase
            var data_sub = force.item_name
            // eslint-disable-next-line camelcase
            var result_sub = data_sub
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
            // eslint-disable-next-line camelcase
            if (result_sub === true) {
              array_item_group_id_hasil.push(force.item_group_id)
            }
          }
          // $.each(field.list, function (x, force) {

          // })
        }
      }
      // eslint-disable-next-line camelcase, vue/no-side-effects-in-computed-properties
      // this.blanko = array_item_group_id_hasil
      console.log(array_item_group_id_hasil)
      // eslint-disable-next-line camelcase, no-unused-vars
      var first_generate = true
      var blanko = []
      var datas = this.blanko
      // eslint-disable-next-line no-unused-vars
      for (const itemhasil of datas) {
        // eslint-disable-next-line camelcase
        if (array_item_group_id_hasil.includes(itemhasil.item_group_id)) {
          blanko.push({
            item_type_id: itemhasil.item_type_id,
            item_group_id: itemhasil.item_group_id,
            // list: this.getDataSingle(item.item_group_id),
            group_name: itemhasil.group_name,
            list: itemhasil.list
          })
        }
      }

      this.blanko = blanko
    },

    accDialog (item) {
      this.dialogShowAcc = true
      this.idorderpenunjang = item.id_order_penunjang
      setTimeout(() => {
        this.$refs.acc.form()
      }, 1000)
    },
    hapusOrder (item) {
      // this.dialogShow = true
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Membatalkan Orderan Ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Batalkan!',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-undef
          $.get(
            baseUrl +
              'labor/batalkan-orderan?id_order=' +
              item.id_order_penunjang,
            (response) => {
              console.log(response)
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = response
              if (con) {
                successMsg(msg)
                this.historyLabor(this.reg, this.rm, this.folio)
              } else {
                errorMsg(msg)
              }
            },
            'JSON'
          )
        } else {
          errorMsg('Nggak Jadi Deh !!!')
        }
      })
    },
    getTypeItem () {
      apiKedua.get('labor/type-labor').then((res) => {
        if (res.data.con) {
          this.listItem = res.data.results
        }
      })
    },

    getOrderHistoryLabor () {
      apiKedua
        .get('labor/get-history-labor-order?no_rm=' + this.rm)
        .then((res) => {})
    },

    getOrderLabor (reg, rm, folio) {
      this.reg = reg
      this.rm = rm
      this.folio = folio

      console.log('NO REG =====' + rm)
      apiKedua.get('labor/list-last-order?no_reg=' + reg).then((res) => {
        const { con, results } = res.data

        if (con) {
          var vue = this
          vue.keterangan = results.keterangan
          results.detail.forEach((item, index) => {
            vue.addTindakan(item.item_id, item.item_name)
          })
          this.cekOrder = results.status !== 'DIORDER'
          this.pesanOrder =
            'Orderan Laboratorium Sudah Dengan Status ' + results.status
        } else {
          this.cekOrder = false
          this.listOrder = []
          this.keterangan = ''
        }
      })
    },
    // eslint-disable-next-line camelcase
    historyLabor () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/history-order?rm=' + this.$route.query.no_rm,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.listHistoryOrder = results
          } else {
            this.listHistoryOrder = []
          }
        },
        'JSON'
      )
      // .then((res) => {
      //   if (res.data.con) {
      //     this.listHistoryOrder = res.data.results
      //   } else {
      //     this.listHistoryOrder = []
      //   }
      //   console.log(this.listHistoryOrder)
      // })
    },
    changes (v) {
      // this.content = v
      apiKedua.get('labor/get-item-by-group?id_group=' + v).then((res) => {
        if (res.data.con) {
          this.items = res.data.results
        }
      })
    },
    addTindakan () {
      this.id_order = this.id_order + 1

      var self = this
      this.listOrder.forEach((val, index) => {
        if (val.item_id === this.tindakan.value) {
          self.listOrder.splice(index, 1)
          return false
        }
      })
      this.listOrder.push({
        no: this.id_order,
        item_id: this.tindakan.value,
        item_name: this.tindakan.text
      })

      this.tindakan = []

      // console.log(this.order)
    },
    settindakan (id, name) {
      this.id_order = this.id_order + 1

      var self = this
      this.listOrder.forEach((val, index) => {
        if (val.item_id === id) {
          self.listOrder.splice(index, 1)
          return false
        }
      })
      this.listOrder.push({
        no: this.id_order,
        item_id: id,
        item_name: name
      })

      this.tindakan = []

      // console.log(this.order)
    },
    deleteTindakan (v) {
      this.listOrder.splice(v, 1)
    },
    simpan () {
      // alert('asdsadas')
      if (this.listOrder.length === 0) {
        errorMsg('Item Tidak Boleh Kosong Dong')
        return
      } else if (!this.keterangan) {
        errorMsg('Keterangan Klinis Tidak Boleh Kosong')
        return
      } else if (!this.jamsampling) {
        errorMsg('Jam Sample Diambil Tidak Boleh Kosong')
        return
      } else if (!this.$route.query.no_rm) {
        errorMsg('Mohon Pilih Pasien Dahulu Yaaah')
        return
      }
      // console.log(this.folioYangDipakai)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        reg_id: this.$route.query.registration_id,
        keterangan: this.keterangan,
        item: this.listOrder,
        jamsampling: this.jamsampling,
        sub_unit: this.$route.query.sub_unit_id,
        acc: this.checkbox,
        jenisPermintaan: this.jenisPermintaan,
        keteranganAcc: this.keteranganAcc
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-hasil-labor',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.listOrder = []
            this.keterangan = ''

            setTimeout(() => {
              successMsg(msg)
              this.historyLabor()
              this.checkbox = ''
              this.jenisPermintaan = ['CITO', 'PERMINTAAN']
              this.keteranganAcc = ''
              this.keterangan = ''
            }, 100)
          } else {
            setTimeout(() => {
              errorMsg(msg)
            }, 500)
          }
        },
        'JSON'
      )
      // console.log(data)

      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(data)], { type: 'application/json' })
      // )
      // apiKedua
      //   .post('labor/simpan-hasil-labor', formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data'
      //     }
      //   })
      //   .then((r) => {
      //     console.log(r)
      //     // eslint-disable-next-line no-unused-vars
      //     const { con, msg, results } = r.data

      //     if (con) {
      //       this.listOrder = []
      //       this.keterangan = ''

      //       setTimeout(() => {
      //         successMsg(msg)
      //         this.historyLabor()
      //         this.checkbox = ''
      //         this.jenisPermintaan = ['CITO', 'PERMINTAAN']
      //         this.keteranganAcc = ''
      //         this.keterangan = ''
      //       }, 100)
      //     } else {
      //       setTimeout(() => {
      //         errorMsg(msg)
      //       }, 500)
      //     }
      //   })
      // console.log(this.rm)
    }
  }
}
</script>
<style scoped>
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
tr {
  padding: 0 !important;
  margin: 0 !important;
}

.v-application ul,
.v-application ol {
  padding-left: 0px !important;
}
li ul .list-group-item {
  position: relative;
  display: block;
  padding: 0.2rem 0.01 1.25rem !important;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  width: 100%;
  padding: 4px;
}
</style>
