<template>
  <div>
    <v-card>
      <v-card-title>&nbsp;<v-icon left>mdi-baby</v-icon>CPPT BAYI</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <h3><b>Nama Dokter Anak :</b> {{ vmodelanak.text }}</h3>
            <v-select
              outlined
              return-object
              item-text="text"
              item-id="id"
              v-model="vmodelanak"
              :items="dokteranak"
              @change="changenamadokter(vmodelanak)"
              dense
              label="Nama Dokter Anak"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <!-- {{ vmodelanak }} -->

            <p class="text-center">
              <strong>Nama Ibu : </strong
              >{{ parent_id.salutation + parent_id.patient_name }} <br />
              <strong>Tanggal Lahir : </strong>{{ parent_id.place_of_birth }} /
              {{ parent_id?.birth_date | moment("DD MMMM YYYY") }}
              <br />
              <strong>No. Rekam Medis : </strong>{{ parent_id.medrec_no }}
              <br />
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <!-- {{ parent_id }} -->
            <p class="text-center">
              <strong>Nama Bayi : </strong>{{ parent_id.namaBayi }} <br />
              <strong>Tanggal Lahir : </strong>
              {{ parent_id.tglbayi | moment("DD MMMM YYYY") }}
              <br />
              <strong>No. Rekam Medis : </strong>{{ parent_id.nmRmBayi }}
              <br />
            </p>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" sm="9" lg="9" md="9">
            <div id="table-scroll" class="table-scroll">
              <div class="table-wrap">
                <table class="table table-bordered table-sm" width="100%">
                  <thead>
                    <tr class="table-primary">
                      <th
                        rowspan="2"
                        class="text-center"
                        style="vertical-align: middle; width: 10%"
                      >
                        TANGGAL/JAM
                      </th>
                      <th
                        rowspan="2"
                        class="text-center"
                        style="vertical-align: middle; width: 30%"
                      >
                        PPA
                      </th>
                      <th
                        colspan="2"
                        class="text-center"
                        style="vertical-align: middle; width: 50%"
                      >
                        <b>S-O-A-P</b>
                        <br />
                        <p>
                          (Dituliskan dengan format SOAP, <br />
                          diserati dengan target yang diukur, evaluasi hasil
                          <br />
                          tata laksana dituliskan dalam assessment).
                        </p>
                      </th>
                      <th
                        rowspan="2"
                        class="text-center"
                        style="vertical-align: middle; width: 20%"
                      >
                        VERIFIKASI DPJP
                      </th>
                    </tr>
                    <tr class="table-info">
                      <th class="text-center" style="vertical-align: middle">
                        Hasil Pemeriksaan
                      </th>
                      <th class="text-center" style="vertical-align: middle">
                        Instruksi Tenaga Kesehatan <br />
                        Termasuk Pasca Bedah/ Prosedur
                      </th>
                    </tr>
                  </thead>

                  <tr v-for="(itemList, index) of formcppt" :key="index">
                    <td style="text-align: center; vertical-align: middle">
                      {{ itemList.created_at }}
                    </td>
                    <td style="text-align: center; vertical-align: middle">
                      {{ itemList.profesi }}
                      <!-- {{ itemList.created_by }} -->
                    </td>
                    <td style="vertical-align: top">
                      <v-list three-line>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-alpha-s</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-html="itemList['subject']">
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-alpha-o</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="itemList['object']"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-alpha-a</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="itemList['asesmen']"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-alpha-p</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="itemList['perencanaan']"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </td>
                    <td
                      style="
                        text-align: left;
                        vertical-align: top;
                        vertical-align: middle;
                      "
                    >
                      <b v-if="itemList.instruksi.length > 0">Verbal Order :</b>
                      <span v-html="itemList['instruksi']"></span>
                      <div
                        v-if="
                          itemList.petugas_1.length > 0 ||
                          itemList.petugas_2.length > 0
                        "
                      >
                        <hr />

                        <p v-if="itemList.petugas_1.length > 0">
                          Nama Petugas 1 :
                          {{ itemList?.petugas_1.nama_petugas }}
                          <br />
                          Jam : {{ itemList?.petugas_1.jam }} <br />
                          Keterangan : {{ itemList?.petugas_1.lain_lainnya }}
                          <br />
                        </p>
                        <hr />
                        <p v-if="itemList.petugas_1.length > 0">
                          Nama Petugas 2 :
                          {{ itemList?.petugas_2.nama_petugas }}
                          <br />
                          Jam : {{ itemList?.petugas_2.jam }} <br />
                          Keterangan : {{ itemList?.petugas_2.lain_lainnya }}
                          <br />
                        </p>
                      </div>
                    </td>
                    <td
                      style="
                        text-align: center;
                        vertical-align: top;
                        vertical-align: middle;
                      "
                    >
                      <span>{{ itemList.verifikasi_dpjp }}</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="3" lg="3" md="3">
            <v-btn
              color="pink darken-5"
              @click="simpancpptbayi"
              outlined
              class="btn-block"
              dark
              >SIMPAN CPPT BAYI</v-btn
            >
            <label class="mb-3 mt-2">🟦 <b>Subject</b></label>
            <ckeditor
              :editor="editor"
              v-model="cpptLast.subject"
              :config="editorConfig"
            ></ckeditor>
            <label class="mb-3 mt-2">🟦 <b>Object</b></label>
            <ckeditor
              :editor="editor"
              v-model="cpptLast.object"
              :config="editorConfig"
            ></ckeditor>
            <label class="mb-3 mt-2">🟦 <b>Asesmen</b></label>
            <ckeditor
              :editor="editor"
              v-model="cpptLast.asesmen"
              :config="editorConfig"
            ></ckeditor>
            <label class="mb-3 mt-2">🟦 <b>Perencanaan</b></label>
            <ckeditor
              :editor="editor"
              v-model="cpptLast.perencanaan"
              :config="editorConfig"
            ></ckeditor>
            <label class="mb-3 mt-2">🟦 <b>Verbal Order</b></label>
            <ckeditor
              :editor="editor"
              v-model="cpptLast.instruksi"
              :config="editorConfig"
            ></ckeditor>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
// eslint-disable-next-line no-unused-vars
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  data () {
    return {
      parent_id: '',
      editor: ClassicEditor,
      vmodelanak: [],
      res: '',
      dokteranak: [
        { id: '12', text: 'Evi Kamelia, dr. Sp.A' },
        { id: '14', text: 'Deddy satriya, Dr. dr.  Sp.A (K)' },
        { id: '224', text: 'Nazardi Oyong, dr. Sp.A' },
        { id: '592', text: 'Elza, dr. Sp.A' },
        { id: '624', text: 'Irawati, dr.SpA' },
        { id: '661', text: 'Effa triani, dr.Sp.A' },
        { id: '1023', text: 'Wella Yurisa,dr.Sp.A' },
        { id: '1050', text: 'Andini,dr. Sp.A' }
      ],
      editorConfig: {
        isReadOnly: false, // Atur ke false agar editor selalu dalam mode penyuntingan
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote'
        ]
      },
      pemakai: JSON.parse(localStorage.getItem('user')),
      formcppt: [],

      cpptLast: {
        id_cppt: '',
        subject: '',
        object: '',
        perencanaan: '',
        asesmen: '',
        instruksi: '',
        tanggal: '',
        verbal: {
          petugas_1: '',
          jam_petugas_1: '',
          lain_nya_petugas_1: '',
          petugas_2: '',
          jam_petugas_2: '',
          lain_nya_petugas_2: ''
        }
      }
    }
  },
  created () {
    // setTimeout(() => {
    //   this.cekDatadokter()
    // }, 100)
  },
  mounted () {
    // setTimeout(() => {
    //   this.cekDatadokter()
    // }, 1000)
  },
  methods: {
    cekDatadokter () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'cppt/cek-nama-dokter-anak',
        { folio_id: this.parent_id.folio_id },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            setTimeout(() => {
              this.vmodelanak = {
                id: results.id,
                text: results.text
              }
            }, 1000)
          }
        },
        'json'
      )
    },

    changenamadokter (RESULTS) {
      console.info(this.parent_id)
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Merubah Nama Dokter Anak.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Simpan Rubah',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-undef
          $.get(
            baseUrlV2 + 'pasien/rubah',
            { id: RESULTS.id, folio_id: this.parent_id.folio_id },
            (res) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res
              if (con) {
                successMsg(msg)
              } else {
                errorMsg(msg)
              }
            },
            'JSON'
          )
        } else {
          // this.disablebuttonSimpan = false
          // this.color = 'primary'
          // resetBtnLoading(btn, html)
          errorMsg('Nggak Jadi Deh !!!')
        }
      })
    },
    getDataBayi () {
      // eslint-disable-next-line no-unused-vars
      const data = {
        rm: this.$route.query.no_rm,
        registration_id: this.$route.query.registration_id,
        sub_unit_id: this.$route.query.sub_unit_id,
        folio_id: this.$route.query.folio_id
      }
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'cppt/simpan-data-bayi',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            setTimeout(() => {
              this.parent_id = results
              this.res = results
              this.getListSoap()
              this.cekDatadokter(results.folio_id)
            }, 1000)
          }
        },
        'JSON'
      )
    },
    deleteCppt (value) {
      var createdby = this.pemakai.employee_name

      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Menghapus CPPT Ini.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-undef
          $.get(
            baseUrl + 'cppt/hapus-by-id',
            { id: value.aksi, by: createdby },
            (res) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res
              if (con) {
                successMsg(msg)
                setTimeout(() => {
                  this.getListSoap()
                }, 1000)
              } else {
                errorMsg(msg + '-' + JSON.stringify(results))
              }
            },
            'JSON'
          )
        }
      })
      console.log(value)
    },
    simpancpptbayi () {
      var createdby = this.pemakai.employee_id
      var namaPembuat = this.pemakai.employee_name
      var namaPasien = this.parent_id.namaBayi
      var norm = this.parent_id.nmRmBayi
      var noreg = this.parent_id.reg_id
      var folioid = this.parent_id.folio_id
      // eslint-disable-next-line no-unused-vars
      var data = {
        cpptLast: this.cpptLast,
        namaPembuat: namaPembuat,
        createdby: createdby,
        namaPasien: namaPasien,
        norm: norm,
        noreg: noreg,
        folioid: folioid,
        verifikasi: this.pemakai.employee_name,
        type: 'rawat-inap',
        is_dokter: this.pemakai.is_dokter
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'cppt/simpan-cppt',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.getListSoap()
            if (!this.cpptLast.id_cppt) {
              this.cpptLast.id_cppt = ''
              this.cpptLast.subject = ''
              this.cpptLast.object = ''
              this.cpptLast.perencanaan = ''
              this.cpptLast.asesmen = ''
              this.cpptLast.instruksi = ''
              this.cpptLast.verbal.petugas_1 = ''
              this.cpptLast.verbal.jam_petugas_1 = ''
              this.cpptLast.verbal.lain_nya_petugas_1 = ''
              this.cpptLast.verbal.petugas_2 = ''
              this.cpptLast.verbal.jam_petugas_2 = ''
              this.cpptLast.verbal.lain_nya_petugas_2 = ''
            }
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
      console.log(data)
    },
    getListSoap () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'cppt/list-cppt-rawat-inap?reg_id=' + this.parent_id.reg_id,
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          console.log(results)
          // eslint-disable-next-line no-unused-vars
          const formcppt = []
          if (con) {
            // eslint-disable-next-line no-unused-vars
            for (const item of results) {
              formcppt.push({
                aksi: item.id_emr_cppt,
                verifikasi_dpjp: item.verifikasi_dpjp,
                created_at: item.created_at,
                no_reg: item.no_reg,
                subject: item.subject,
                profesi: item.profesi,
                object: item.object,
                created_by: item.created_by,
                asesmen: item.asesmen,
                perencanaan: item.perencanaan,
                instruksi: item.instruksi,
                petugas_1: JSON.parse(item.petugas_1),
                petugas_2: JSON.parse(item.petugas_2),
                is_double_check: item.is_double_check
              })
            }
          } else {
          }

          this.formcppt = formcppt
        },
        'JSON'
      )
    }
  }
}
</script>
<style scoped>
.table-scroll {
  position: relative;
  max-width: 1800px;
  margin: auto;
  overflow: hidden;
  /* border: 1px solid #000; */
}

.table-wrap {
  width: 100%;
  overflow: auto;
}

.table-scroll table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  border: 0.5px solid #000;
  /* background: #fff; */
  white-space: nowrap;
  vertical-align: top;
}

.table-scroll thead,
.table-scroll tfoot {
  background: #f9f9f9;
}

.clone {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.clone th,
.clone td {
  visibility: hidden;
}

.clone td,
.clone th {
  border-color: transparent;
}

.clone tbody th {
  visibility: visible;
  color: red;
}

.clone .fixed-side {
  border: 1px solid #000;
  background: #eee;
  visibility: visible;
}

.clone thead,
.clone tfoot {
  background: transparent;
}
hr {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
