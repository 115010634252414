import { render, staticRenderFns } from "./Laboratorium.vue?vue&type=template&id=32ab9ad7&scoped=true&"
import script from "./Laboratorium.vue?vue&type=script&lang=js&"
export * from "./Laboratorium.vue?vue&type=script&lang=js&"
import style0 from "./Laboratorium.vue?vue&type=style&index=0&id=32ab9ad7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ab9ad7",
  null
  
)

export default component.exports